import React, { Children } from 'react';
/* Admin Routes */
// import { checkRoles, loggedInUser } from "./components/Common/Helpers.js"

import Login from "./views/modules/Login.js";
const PlansSetting = React.lazy(()=>import('./views/modules/PlansSettings/Index.js'));
const Users = React.lazy(()=>import('./views/modules/User/Index.js'));
const Dashboard = React.lazy(() => import('./views/modules/Dashboard/Index.js'));
const History = React.lazy(() => import('./views/modules/Payment/Index.js'));
const CustomPlanRequest = React.lazy(()=>import('./views/modules/CustomPlanRequest/Index.js'));

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-bullet-list-67 text-info",
    component: Dashboard,
    layout: "/admin",
    is_visible: true,
    // children:[
    //   {
    //   path: "/dash",
    //   name: "Stats",
    //   icon: "fa fa-list-alt",
    //   component: PlansSetting,
    //   layout: "/admin",
    //   is_visible: true,
    //  },
    // ]
  },
  {
    path: "/plan-settings",
    name: "Settings",
    icon: "ni ni-settings text-info",
    component: PlansSetting,
    layout: "/admin",
    is_visible: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: "fas fa-user text-info",
    component: Users,
    layout: "/admin",
    is_visible: true,
  },
  {
    path: "/history",
    name: "History",
    icon: "fa fa-history text-info",
    component: History,
    layout: "/admin",
    is_visible: true,
  },
  {
    path: "/custom-plan-request",
    name: "Custom Plan Request",
    icon: "fa fa-duotone fa-solid fa-comment-dots text-info",   
    component: CustomPlanRequest,
    layout: "/admin",
    is_visible: true,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  }
];
export default routes;
